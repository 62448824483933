<template>
<b-row class="my-4">
    <b-col cols="12" class="d-flex justify-content-center">
        <b-form-input class="searchBar" v-model="search" size="lg" :placeholder="$t('search.my-media')" autocomplete="new-password" @input="searchMyMedia"></b-form-input>
      <!--   {{$t('search.show-draft')}}   <b-form-checkbox id="languageEnabled" v-model="showDraft" switch size="md" @change="showHideDraft"></b-form-checkbox> -->
    </b-col>
</b-row>
</template>

<script>
export default {
  name: 'SearchAndFilter',
  components: {  },
  data: function() {
    return {
      search: "",
      showDraft: true
    }
  },

  methods: {
    searchMyMedia() {
      this.$emit("searchMyMedia", this.search)
    }
  }
}
</script>

<style></style>
