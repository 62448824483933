<template>
  <section class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t("general.dashboard", [user.enterpriseShortName]) | removeLat }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12">
          <SearchAndFilter @searchMyMedia="search"/>
        </b-col>
        <b-col class="p-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="myMedia" />
          </b-overlay>
           <b-row v-if="loadMore">
            <b-col>
              <p class="text-center">
                <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import SearchAndFilter from '@/components/admin/_utils_/SearchAndFilter.vue'
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'
export default {
  name: 'Dashboard',
  components: { NavBar, MediaGridCMS, SearchAndFilter },
  data: function() {
    return {
      media: [],
      showOverlay: true,
      loadMore: false,
      page: 0,
      totalPages: 0,
      user: this.$root.user
    }
  },
  computed : {
    myMedia : function() {
      return this.media;
    },
    _lang: function() {
      return this.$root.lang
    }
  },
  created : async function () {
    this.fetchData();
  },
  methods: {
    mediaShowHideDraft: async function(value) {
      this.showOverlay = true;
      var apiUrl = "/cms/api/media/draft/" + value;
      if (value) {
        apiUrl = "/cms/api/media/all";
      }
      await this.$http.get(apiUrl, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.media = r.data.content;
        this.showOverlay = false;
      }).catch(e => {
        var message = this.handleError(e);
        this.showOverlay = false;
      })
      this.showOverlay = false;
    },

    search (keyword) {
      this.showOverlay = true;
      this.$http.post("/cms/api/media/my/search", { keyword : keyword }, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.media = r.data.content;
      }).catch(e => {
        var message = this.handleError(e);
        this.showOverlay = false;
      })
      this.showOverlay = false;
    },

    async loadMoreData() {
      this.page++;
      await this.$http.get("/cms/api/media/my/" + this.$root.lang.IANA + "?page=" + this.page, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
      }).catch(e => {
        var message = this.handleError(e);
      })
    },

    async fetchData() {
      this.showOverlay = true;
      // Učitaj prvu stranu na datom jeziku.
      await this.$http.get("/cms/api/media/my/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        // console.log(r);
        this.media = [];
        this.media = r.data.content;
        this.loadMore = r.data.hasNext;
        this.totalPages = r.data.totalPages;
      }).catch(e => { this.handleError(e); });

      // Ako je učitana još koja strana, učitaj i njih
      // Bkvy emuliranje kliktanja na dugme učitaj još.
      if (this.page > 0) {
        // console.log(this.page);
        const openedPages = this.page;
        this.page = -1;
        this.media = [];
        for (let i = 0; i <= openedPages; i++) {
          // console.log("Učitavam stranu: " + i);
          await this.loadMoreData()
        }
      }
      this.showOverlay = false;
    }
  },
  watch: {
    async _lang(newLang, oldLang) {
      this.fetchData();
      // this.page = 0;
    }
  }
}
</script>

<style></style>
